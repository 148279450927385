import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../../components/Layout";
import { StaticImage } from "gatsby-plugin-image";
import Coctelescon from "../../components/Sabores/CoctelesconTR";
import CarruselCompra from "../../components/CarruselCompra";
import BribeRecetas from "../../components/BribeRecetas";
import CarruselSabores from "../../components/CarruselSabores";
import Seo from "../../components/Seo";

function tropical() {
  const { strapiTag } = useStaticQuery(graphql`
    query {
      strapiTag(name: { eq: "Sun Peak Peach" }) {
        articles {
          id
          slug
          title
          description
          cover {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.77)
              }
            }
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <Seo
        seo={{
          metaTitle: "Tropical",
          metaDescription:
            "Boone’s Tropical cuenta con un rico y natural toque de frutas tropicales como piña, naranja y toques cítricos.",
        }}
      />
      <header>
        <StaticImage
          src="../../assets/images/sabores/tropical/TROPICAL-BANNER.png"
          alt="Boones Tropical"
          className="hero-img"
          placeholder="tracedSVG"
          layout="fullWidth"
        />
        <div className="container relative -mt-5 mb-20 md:-mt-28">
          <div className="z-10 flex flex-wrap justify-center gap-4">
            <div className="max-w-xl space-x-4 space-y-2 rounded-xl bg-white p-5 drop-shadow-xl md:h-56">
              <h1 className="text-center capitalize text-tropical">
                Tu lado más veraniego
              </h1>
              <p className="max-w-prose font-light">
                Boone’s Tropical cuenta con un rico y natural toque de frutas
                tropicales como piña, naranja y toques cítricos.
              </p>
              <p className="max-w-prose font-light">
                El complemento ideal para esas tardes de verano con los amigos o
                una tarde para ti. Con Boone’s Tropical es
                #ImposibleEquivocarse.
              </p>
            </div>
          </div>
        </div>
      </header>
      <main className="container">
        <div>
          <StaticImage
            src="../../assets/images/sabores/tropical/info-tropical-desktop.png"
            alt="Boones Tropical"
            className="hero-img"
            placeholder="tracedSVG"
            layout="fullWidth"
          />
        </div>
        <div className="mt-4">
          <Coctelescon articles={strapiTag.articles} />
        </div>
        <div className="mt-16">
          <CarruselCompra />
        </div>
        <div className="mt-16">
          <BribeRecetas />
        </div>
        <div className="mt-16">
          <CarruselSabores />
        </div>
      </main>
    </Layout>
  );
}

export default tropical;
